import anime from 'animejs';

function iconMove(el, iconElement, level) {

  anime({
    targets: iconElement,
    translateX: (el.x - (window.innerWidth / 2)) / level,
    translateY: (el.y - (window.innerHeight / 2)) / level,
  });
}

function resetIcon(el) {
  anime({
    targets: el,
    translateX: 0,
    translateY: 0,
  });
}

function headerAlpha(el, scrollY) {
  const n = scrollY / 150;

  anime({
    targets: el,
    background: `rgba(74,16,121,${n})`,
  });
}

function platformMove(el) {
  anime({
    targets: el.target.lastChild,
    translateX: el.offsetX - 30,
    translateY: el.offsetY - 30,
  });
}

function platformLeave(el) {
  anime({
    targets: el.target.lastChild,
    translateX: 0,
    translateY: 0,
  });
}



function elementUpward(el, zValue, level) {
  if (zValue >= 150) {
    zValue = 150;
  } else if (zValue <= 5) {
    zValue = 5;
  }

  anime({
    targets: el,
    translateZ: `-${zValue * level}`,
    easing:"linear",
    duration:100
  });
}

function fanRotation(el) {
  anime({
    targets: el,
    rotateZ: '1800deg',
  });
}

function searchUpward(el) {
  anime({
    targets: el,
    translateY:-100
  })
}

export {
  iconMove,
  resetIcon,
  headerAlpha,
  platformMove,
  platformLeave,
  elementUpward,
  fanRotation,
  searchUpward
};
