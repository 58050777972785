<template>
  <div id="show-video_page">
    <div id="show-video_box">
      <h1>Blazing Fast Performance</h1>
      <div id="video_shell">
        <video autoplay muted loop>
          <source src="../../public/video/video.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
#show-video_page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 55px;

  #show-video_box {
    padding: 0 10px;

    h1 {
      width: 25rem;
      font-size: 4rem;
      margin-bottom: 40px;
    }

    #video_shell {
      padding: 50px 50px 0 50px;
      background: linear-gradient(135deg, #ec3f7d, #5e7ae3);
      border-radius: 10px;
      video {
        width: 100%;
        border: none;
        border-radius: 10px;
      }
    }
  }
}
</style>
