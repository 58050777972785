<template>
  <div id="footer">
    <div id="last-show" @mousemove="mouseMove" @mouseleave="mouseLeave">
      <div id="last-content_box">
        <h1>Make Chrome Faster</h1>
        <p>Mighty makes Google Chrome faster & uses 10x less memory.</p>
        <button>Request Access</button>
      </div>
      <img
        src="../../public/Icons/last-show_icon.webp"
        alt="last_Icon"
        id="last_icon"
        ref="MElement"
      />
    </div>
    <footer>
      <img src="../../public/Icons/logo.svg" alt="footerLogo" />
      <ul>
        <li>
          <a href="#"> privacy statement </a>
        </li>
        <li>
          <a href="#">terms of service</a>
        </li>
        <li>
          <a href="#">we're hiring!</a>
        </li>
      </ul>
      <div id="footer-icons_box">
        <a href="#">
          <img src="../../public/Icons/twitter-small.svg" alt="twitter" />
        </a>
        <a href="#">
          <img src="../../public/Icons/in-small.svg" alt="in" />
        </a>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { iconMove, resetIcon } from '../animate';

const MElement = ref(null);
// const BtnElement = ref(null);

const mouseMove = (e) => {
  iconMove(e, MElement.value, 20);
};

const mouseLeave = () => {
  resetIcon(MElement.value);
};
</script>

<style lang="scss" scoped>
#footer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  #last-show {
    display: grid;
    place-items: center;
    position: relative;
    flex-grow: 1;

    #last-content_box {
      display: flex;
      align-items: center;
      flex-direction: column;
      z-index: 10;
      transform-style: preserve-3d;
      perspective: 100px;

      h1 {
        font-size: 4rem;
      }

      p {
        font-size: 1.2rem;
        font-weight: 500;
      }

      button {
        margin-top: 25px;
        border: none;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 800;
        text-transform: uppercase;
        width: 210px;
        height: 60px;
        letter-spacing: 0.7px;
        transition: font-size linear 0.3s 0s;
        transform-origin: center center;
        cursor: pointer;
      }

      button:hover {
        font-size: 0.8rem;
      }

      button:hover #last_icon {
        transform: scaleZ(10);
      }
    }

    #last_icon {
      width: 100%;
      position: absolute;
      transform-style: preserve-3d;
      transition: all linear 0.1s 0s;
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 35px;
    z-index: 10;
    background-color: $footer-background-color;

    ul {
      display: flex;

      li {
        margin: 0 10px;

        a {
          color: #fff;
        }
      }
    }

    #footer-icons_box {
      a {
        margin: 0 5px;
      }
    }
  }
}
</style>
