<template>
  <div id="hero_main" @mouseleave="reset" @mousemove="heroMove">
    <div id="hero-left">
      <div class="hero-content_box">
        <h1 class="hero-title">
          Make Chrome
          <span>Faster</span>
        </h1>
        <p class="hero-text-content">
          Mighty is a new browser that lets you have more tabs while taking 10x
          less memory. Built for speed, Mighty streams your browser from a
          powerful computer in the cloud.
        </p>
      </div>
      <div class="hero-button_box">
        <a href="#" class="hero_btn first">Request Access</a>
        <a href="#" class="hero_btn last">
          <img src="../../public/Icons/play.svg" alt="play_btn" />
        </a>
      </div>
    </div>
    <div id="hero-right">
      <img
        src="../../public/Icons/hero_father.webp"
        alt="3D-M_IMG"
        class="hero-right-icon_main"
        ref="iconFather"
      />
      <img
        src="../../public/Icons/hero_notion.webp"
        alt="3D-Notion_IMG"
        class="hero-right_icon notion"
        ref="notionElement"
      />
      <img
        src="../../public/Icons/hero_google.webp"
        alt="3D-Google_IMG"
        class="hero-right_icon google"
        ref="googleElement"
      />
      <img
        src="../../public/Icons/hero_unknow.webp"
        alt="unknow logo"
        class="hero-right_icon unknow"
        ref="unknow"
      />
      <img
        src="../../public/Icons/figma.webp"
        alt=""
        class="hero-right_icon figma"
        ref="figma"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { iconMove, resetIcon } from '../animate';

// get iconElements
const iconFather = ref(null),
  notionElement = ref(null),
  googleElement = ref(null),
  unknow = ref(null),
  figma = ref(null);

// onMouseMoveing run animejs function
const heroMove = (e) => {
  iconMove(e, iconFather.value, 20);
  iconMove(e, notionElement.value, 25);
  iconMove(e, googleElement.value, 24);
  iconMove(e, unknow.value, 25);
  iconMove(e, figma.value, 26);
};

// onMouseLeave reset anime
const reset = () => {
  resetIcon(iconFather.value);
  resetIcon(notionElement.value);
  resetIcon(googleElement.value);
  resetIcon(unknow.value);
  resetIcon(figma.value);
};
</script>

<style lang="scss">
#hero_main {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  padding: 0 15px;
  overflow: hidden;

  #hero-left {
    grid-column-start: 1;
    grid-column-end: 2;

    .hero-content_box {
      max-width: 400px;

      .hero-title {
        font-weight: 800;
        font-size: $font-big-size;
        line-height: 4rem;

        span {
          font-style: italic;
        }
      }

      .hero-text-content {
        font-size: 1.5rem;
        font-weight: 800;
        margin: 1rem 0;
        color: $font-violet-color;
      }
    }

    .hero-button_box {
      display: flex;
      align-items: center;

      .hero_btn {
        color: #fff;
      }
      .first {
        padding: 15px 20px;
        border-radius: 10px;
        background: linear-gradient(45deg, #f33f78, #fc8550);
        text-transform: uppercase;
        font-weight: 800;
      }
      .last {
        margin-left: 20px;
      }
    }
  }
  #hero-right {
    position: relative;
    grid-column-start: 2;
    grid-column-end: 4;
    overflow: hidden;
    img {
      transition: all linear 0.1s 0s;
    }

    .hero-right-icon_main {
      width: 100%;
      //   margin-top: 10px;
    }

    .hero-right_icon {
      position: absolute;
      width: 25%;
      left: 10%;
    }

    .notion {
      top: 10%;
    }
    .google {
      top: 25%;
    }
    .unknow {
      top: 40%;
    }
    .figma {
      top: 55%;
    }
  }
}
</style>
