<template>
  <div id="showit-level02" @enter="testShow">
    <div id="title-text_box">
      <h1>Heavy web apps, meet the cloud browser.</h1>
      <p>
        Mighty streams your browser from a powerful computer in the cloud. You
        won't feel lag while typing, moving your mouse, or scrolling. Busy
        processing is offloaded when you work with heavy web apps like Figma,
        Airtable, or Gmail. A micro footprint with Mighty functionality.
      </p>
    </div>
    <div id="showit-content_shell">
      <div id="showit-content_box">
        <div class="fan_box">
          <img src="../../public/Imgs/fan-shell.webp" />
          <img src="../../public/Imgs/fan-page.webp" ref="fanPage" />
        </div>
        <div class="showit-text-content">
          <h1>Work without the fan noise.</h1>
          <p>
            Busy processing is moved into the cloud so your computer won’t sound
            like a jumbo jet. You don’t need a computer with bleeding edge
            thermal efficiency now. Do intense work, have more tabs open, and
            multi-task without the noise.
          </p>
        </div>
      </div>
    </div>
    <div id="comment_shell">
      <div class="comment_box">
        <p id="comment">
          On a dark desert highway. Cool wind in my hair.On a dark desert
          highway. Cool wind in my hair.On a dark desert highway. Cool wind in
          my hair.On a dark desert highway. Cool wind in my hair.
        </p>
        <div id="reviewer_box">
          <img src="../../public/Imgs/picoDang.webp" alt="reviewerPhoto" />
          <div class="reviewer-infomation">
            <h4>Mr.Pico</h4>
            <p>CREATER @ Discord-CFNF</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { fanRotation } from '../animate';

const fanPage = ref(null);

onMounted(() => {
  self.addEventListener('scroll', () => {
    if (window.scrollY >= 3200 && window.screenY <= 4200) {
      fanRotation(fanPage.value);
    }
  });
});
</script>

<style lang="scss" scoped>
#showit-level02 {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;

  #title-text_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;

    h1 {
      max-width: 45%;
      font-size: 2.8rem;
    }

    p {
      max-width: 50%;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 30px;
      color: $font-violet-color;
    }
  }

  #title-text_box > * {
    text-align: center;
  }

  #showit-content_shell {
    width: 85%;
    max-width: 1500px;
    display: grid;
    place-items: center;
    padding: 5px;
    background: linear-gradient(165deg, #6061ed, #c167fe, #ffa34f);
    border-radius: 20px;

    #showit-content_box {
      height: 100%;
      display: grid;
      padding: 0 15px;
      grid-template-columns: repeat(3, 1fr);
      place-items: center;
      background: $main-background-color;
      border-radius: 20px;

      .fan_box {
        width: 500px;
        height: 500px;
        position: relative;
        grid-column-start: 1;
        grid-column-end: 2;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          position: absolute;
          transition: all ease-out 1s 0s;
        }
      }

      .showit-text-content {
        grid-column-start: 2;
        grid-column-end: 4;

        h1 {
          // width: 50rem;
          font-size: 3rem;
          line-height: 3rem;
          margin-bottom: 15px;
        }

        p {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    #showit-content_shell {
      width: 90%;
    }
  }

  #comment_shell {
    display: flex;
    justify-content: center;
    margin: 100px 0;

    .comment_box {
      display: flex;
      flex-direction: column;
      width: 750px;
      padding: 30px;
      border-radius: 10px;
      background: linear-gradient(135deg, #ce4097, #5c82e4);

      #comment {
        font-size: 1.2rem;
        font-weight: 700;
      }

      #reviewer_box {
        height: 55px;
        display: flex;
        align-items: center;
        margin-top: 15px;

        img {
          height: 100%;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
