<template>
  <header ref="headerElement">
    <img src="../../public/Icons/logo.svg" alt="logo" id="header_logo" />
    <div id="pick_items">
      <ul>
        <li>
          <a href="#show-video_page">Performance</a>
        </li>
        <li>
          <a href="#">Tech Specs</a>
        </li>
        <li>
          <a href="#"> Security </a>
        </li>
        <li>
          <a href="#"> We're Hiring </a>
        </li>
        <li id="special">
          <a href="#"> Request Access </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { headerAlpha } from '../animate';

const headerElement = ref(null);

// listen windowScroll
onMounted(() => {
  self.addEventListener('scroll', () => {
    headerAlpha(headerElement.value, window.scrollY);
  });
});
</script>

<style lang="scss" scoped>
header {
  min-width: $global-min-width;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 15px;
  z-index: 99;
  transition: background linear 0.1s 0s;

  @media (max-width: 1200px) {
    justify-content: space-between;
  }

  #pick_items {
    ul {
      display: flex;
      align-items: center;

      li {
        margin: 0 8px;
      }
      a {
        color: #fff;
      }
      #special {
        border: 3px solid #bd17cf;
        border-radius: 6px;
        padding: 5px 10px;
      }
    }
  }
}
</style>
