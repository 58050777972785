<template>
  <div id="showit-level01">
    <div id="show-platform">
      <div class="platform_box">
        <div
          class="platform_item Calendar"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img
            src="../../public/Icons/googleCalendar.svg"
            alt="googleCalendar"
          />
        </div>
        <div
          class="platform_item notion"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/notion.svg" alt="notion" />
        </div>
        <div
          class="platform_item in"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/in.svg" alt="in" />
        </div>
        <div
          class="platform_item google"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/google.svg" alt="google" />
        </div>
        <div
          class="platform_item box"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/box.svg" alt="box" />
        </div>
        <div
          class="platform_item apple"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/apple.svg" alt="apple" />
        </div>
        <div
          class="platform_item spotify"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/spotify.svg" alt="spotify" />
        </div>
        <div
          class="platform_item github"
          @mousemove.self="onHover"
          @mouseleave.self="onLeave"
        >
          <img src="../../public/Icons/github.svg" alt="github" />
        </div>
      </div>
      <div class="platform-decoration"></div>
    </div>
    <div id="shwo-it_content">
      <h1>More Tabs, Less Memory</h1>
      <p>
        Mighty uses 10x less memory than Google Chrome. Load hundreds of tabs
        without it stalling, freezing, and slowing down your computer.
      </p>
    </div>
    <div id="show-pages">
      <div class="show-pages_item left">
        <div class="page-img transparent-cards" ref="transparentCardLeft"></div>
        <img
          src="../../public/Imgs/left03.webp"
          ref="leftLast"
          class="page-img left-img-last"
        />
        <img
          src="../../public/Imgs/left02.webp"
          ref="leftC"
          class="page-img left-img-c"
        />
        <img src="../../public/Imgs/left01.webp" alt="" />
      </div>
      <div class="show-pages_item center">
        <div class="page-img transparent-cards" ref="transparentCardCenter"></div>
        <img
          src="../../public/Imgs/center03.webp"
          class="page-img center-img-last"
          ref="centerLast"
        />
        <img
          src="../../public/Imgs/center02.webp"
          class="page-img center-img-c"
          ref="centerC"
        />
        <img src="../../public/Imgs/center01.webp" alt="" />
      </div>
      <div class="show-pages_item right">
        <div class="page-img transparent-cards" ref="transparentCardRight"></div>
        <img
          src="../../public/Imgs/right03.webp"
          class="page-img right-img-last"
          ref="rightLast"
        />
        <img
          src="../../public/Imgs/right02.webp"
          class="page-img right-img-c"
          ref="rightC"
        />
        <img src="../../public/Imgs/right01.webp" alt="" />
      </div>
    </div>
    <div id="comment_shell">
      <div class="comment_box">
        <p id="comment">
          Do the Dance, Do the D-A-N-C-E~, One two three four five, just ease as
          A-B-C. Do the Dance, Do the D-A-N-C-E~, One two three four five, just
          ease as A-B-C. Do the Dance, Do the D-A-N-C-E~, One two three four
          five, just ease as A-B-C.
        </p>
        <div id="reviewer_box">
          <img src="../../public/Imgs/picoDang.webp" alt="reviewerPhoto" />
          <div class="reviewer-infomation">
            <h4>Mr.Pico</h4>
            <p>CREATER @ Discord-CFNF</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { platformMove, platformLeave, elementUpward } from '../animate';

// page-elements
const transparentCardLeft = ref(null),
leftLast = ref(null),
leftC = ref(null),
transparentCardCenter = ref(null),
centerLast = ref(null),
centerC = ref(null),
transparentCardRight = ref(null),
rightLast = ref(null),
rightC = ref(null);

// Listen Mouse Move Function
const onHover = (e) => {
  platformMove(e);
};

const onLeave = (e) => {
  console.log('run');
  platformLeave(e);
};

onMounted(() => {
  let Zvalue = 0;

  self.addEventListener('scroll', () => {
    Zvalue = window.scrollY - 820;

    elementUpward(transparentCardLeft.value,Zvalue,1.5)
    elementUpward(transparentCardCenter.value,Zvalue,1.5)
    elementUpward(transparentCardRight.value,Zvalue,1.5)
    elementUpward(leftLast.value, Zvalue, 1);
    elementUpward(centerLast.value, Zvalue, 1);
    elementUpward(rightLast.value, Zvalue, 1);
    elementUpward(leftC.value, Zvalue, 0.5);
    elementUpward(centerC.value, Zvalue, 0.5);
    elementUpward(rightC.value, Zvalue, 0.5);
  });
});
</script>

<style lang="scss" scoped>
#showit-level01 {
  display: flex;
  flex-direction: column;
  justify-content: center;

  #show-platform {
    display: block;

    .platform_box {
      display: flex;
      justify-content: space-evenly;
      .platform_item {
        padding: 10px;
        // overflow: hidden;

        img {
          max-width: 40px;
          width: 100%;
          transition: all linear 0.1s 0s;
        }
      }
    }
    .platform-decoration {
      width: 100%;
      height: 4px;
      background: linear-gradient(
        90deg,
        #8a64f4,
        #e081b7,
        #f69a6e,
        #fdcc80,
        #ffa250
      );
    }
  }

  #shwo-it_content {
    margin: 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    h1 {
      font-size: $font-big-size;
      max-width: 30rem;
    }

    p {
      font-size: $font-general-size;
      max-width: 28rem;
      color: $font-violet-color;
    }
  }

  @media (min-width: 3000px) {
    #shwo-it_content {
      justify-content: space-evenly;
    }
  }

  #show-pages {
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1440px;
    perspective-origin: 50% -100%;
    padding-top: 80px;

    .show-pages_item {
      position: relative;
      width: 600px;
      display: grid;
      align-items: end;
      transform-style: preserve-3d;

      img,.transparent-cards {
        width: 100%;
        border-radius: 6px;
      }
      .page-img {
        position: absolute;
        transform: scale3d(1, 1, 1);
      }

      .transparent-cards {
        height: 100%;
        background: rgba( 255, 255, 255, 0.15 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 3px );
        -webkit-backdrop-filter: blur( 3px );
        border: 1px solid rgba( 255, 255, 255, 0.18 );
      }
    }
    .left {
      margin-right: 3%;
    }
    .center {
      margin: 0 1%;
    }
    .right {
      margin-left: 3%;
    }

    .left {
      justify-self: start;
    }
    .center {
      justify-self: center;
    }
    .right {
      justify-self: end;
    }
  }

  #comment_shell {
    display: flex;
    justify-content: center;
    margin-top: 55px;

    .comment_box {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      width: 750px;
      padding: 30px;
      border-radius: 10px;
      background: linear-gradient(135deg, #f34178, #fb884d);

      #comment {
        // flex-grow: 1;
        font-size: 1.2rem;
        font-weight: 700;
      }

      #reviewer_box {
        height: 55px;
        display: flex;
        align-items: center;
        margin-top: 15px;
        img {
          height: 100%;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
